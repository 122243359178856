/**
 * 判断移动端
 * @returns bool
 */
export function isMobile() {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

/**
 * 判断宽度 >= 768 的设备
 * @returns bool
 */
export function isLaptop() {
  const flag = window.screen.availWidth >= 768
  return flag
}